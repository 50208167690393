/*Outside slide containers*/

.slider__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: auto;
}

.slider__inner-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.slider__content-container {
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;
}

/* Slider Side Buttons */

.slider__arrow--right,
.slider__arrow--left {
  touch-action: manipulation;
  max-width: 36px;
  min-width: 36px;
}
.slider__arrow--right {
  margin-left: auto;
}

.slider__arrow--left {
  margin-right: auto;
}

/* Bottom circle buttons */

.slider__dots-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.slider__button {
  background-color: #d2d3dd;
  border-radius: 50px;
  margin: 0 3%;
  width: 10px;
  padding: 0;
  height: 10px;
  border: none;
}

.slider__button:hover {
  cursor: pointer;
}

.slider__button--active {
  background-color: #232326;
}

@media only screen and (max-width: 600px) {
  .slider__arrow--right,
  .slider__arrow--left {
    max-width: 18px;
    min-width: 18px;
  }
}
