.MenuContainer{
  position: relative;
  width: calc(100% - 48px);
  /* min-height: calc(100vh - 48px); */
  padding: 24px;
}

.MenuCategoryContainer{
  position: relative;
  display: inline-block;
  width: 27%;
  margin: 24px;
  vertical-align: top;
}

.MenuCategory{
  font-size: 24px;
  font-weight: 700;
  margin: 4px 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.MenuItem{
  position: relative;
  font-size: 16px;
  font-weight: 300;
  margin: 2px 4px;
  width: calc(100% - 72px);
  padding-right: 72px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.MenuItemPrice{
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 800px){
  .MenuCategoryContainer{
    width: calc(100% - 48px);
  }
}