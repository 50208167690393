.ReviewsContainer {
  position: relative;
  width: calc(100% - 48px);
  padding: 24px;
}

.ReviewsCardContainer {
  margin: 64px inherit;
  width: 70%;
}

/* .ReviewsCardContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  width: 12%;
  height: 100%;
  z-index: 9;
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.001));
}

.ReviewsCardContainer::after {
  content: "";
  position: absolute;
  top: 0;
  right: -2px;
  width: 12%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.001), #fff);
} */

.ReviewWrapper {
  background: #fff;
  position: relative;
}

.ReviewCard {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  margin: 16px 12px;
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReviewCardLogo {
  width: 128px;
  margin: auto;
  padding: 12px;
}

.ReviewCardHeader {
  font-size: 18px;
  font-weight: 900;
  color: #222;
}

.ReviewCardButton {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  padding: 8px 16px;
  border: 0;
  border-radius: 12px;
  box-shadow: 1px 5px 10px -3px #00000088;
  transition: all 0.2s;
  margin: 4px auto;
  text-transform: uppercase;
}

.ReviewCardButton:hover {
  transform: scale(1.02);
  box-shadow: 1px 10px 12px -3px #00000088;
  cursor: pointer;
}

.ReviewsCardHours {
  font-size: 14px;
  font-weight: 200;
  color: #222;
  padding: 12px;
}

.ReviewsCardsNext {
  transition: all 0.2s;
  opacity: 0.4;
  cursor: pointer;
}

.ReviewsCardsNext:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

.ReviewsCardsPrev {
  transition: all 0.2s;
  opacity: 0.4;
  cursor: pointer;
}

.ReviewsCardsPrev:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

.ReviewCardBody {
  font-size: 16px;
  font-weight: 600;
  color: #4b4b4d;
  padding: 8px 0px 24px 0;
  font-weight: 500;
  position: relative;
  position: relative;
  overflow-y: auto;
}

@media only screen and (max-width: 800px) {
  .ReviewsCardContainer {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .ReviewCardButton {
    padding: 8px 16px;
    width: 100%;
  }
  /* .ReviewCardBody{
    font-size: 12px;
    font-weight: 500;
    color: #666;
    padding: 8px 0px;
  } */
  .ReviewsCardsNext {
    transition: all 0.2s;
    opacity: 0.4;
    cursor: pointer;
  }

  .ReviewsCardsPrev {
    transition: all 0.2s;
    opacity: 0.4;
    cursor: pointer;
  }
}
