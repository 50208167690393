.SectionHeaderContainer{
  position: relative;
  display: inline-block;
  padding: 8px 32px;
  margin: 24px;
  color: #fff;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 2px;
  text-shadow: 1px 4px 8px #00000066;
  vertical-align: middle;
}

.SectionHeaderMenuLink{
  position: relative;
  display: inline-block;
  padding: 32px;
  font-size: 24px;
  font-weight: 300;
  vertical-align: middle;
  text-align: center;
}

.SectionHeaderMenuImg{
  position: relative;
  display: inline-block;
  padding: 8px;
  width: 32px;
  vertical-align: middle;
}

@media only screen and (max-width: 800px){
  .SectionHeaderContainer{
    font-size: 32px;
    margin: 12px;
    line-height: 1;
  }
}