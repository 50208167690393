.Background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
  animation: fade-in .5s forwards ease;
  -webkit-animation: fade-in .5s forwards ease;
  background: rgba(0,0,0,0.5);
}

.Modal{
  position: relative;
  width: 96%;
  box-sizing: border-box;
  height: auto;
  min-height: 100px;
  max-height: 92%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  z-index: 11;
  animation: slide-up .5s forwards ease;
  -webkit-animation: slide-up .5s forwards ease;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.Close{
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: .8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

#ModalExit{
  animation: drop-out .5s forwards ease;
  -webkit-animation: drop-out .5s forwards ease;
}

#BackgroundExit{
  animation: fade-out .5s forwards ease;
  -webkit-animation: fade-out .5s forwards ease;
}

@keyframes slide-up {
  0%{
    transform: translateY(100vh);
  }
  60%{
    transform: translateY(-6vh);
  }
  100%{
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-up {
  0%{
    transform: translateY(100vh);
  }
  60%{
    transform: translateY(-6vh);
  }
  100%{
    transform: translateY(0vh);
  }
}

@keyframes drop-out {
  0%{
    transform: translateY(0vh);
  }
  40%{
    transform: translateY(-6vh);
  }
  100%{
    transform: translateY(100vh);
  }
}



@keyframes fade-out {
  0%{
    background: rgba(0,0,0,.5);
  }
  100%{
    background: rgba(0,0,0,0);
  }
}

@keyframes fade-in {
  0%{
    background: rgba(0,0,0,0);
  }
  100%{
    background: rgba(0,0,0,0.5);
  }
}