.HeaderContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 8px;

  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 10;
}

.HeaderButton {
  border-radius: 8px;
  padding: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 8px;
  text-align: center;
}

.HeaderButton:hover {
  transform: scale(1.01);
  box-shadow: 1px 5px 10px -1px #00000066;
}

.HeaderImg {
  max-height: 64px;
  max-width: 64px;
  padding: 6px;
  transition: all 0.2s;
  margin-right: 16px;
}
.HeaderSocialImg {
  height: 24px;
  width: 24px;
  padding: 18px 8px;
  transition: all 0.2s;
  opacity: 0.5;
}

.HeaderSocialImg:hover {
  opacity: 1;
  cursor: pointer;
}

.HeaderLink,
.HeaderLinkMobile {
  background: none;
  border: none;
  font-family: inherit !important;
}

.HeaderLinks {
  height: 100%;
  width: 100%;
  margin-right: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.HeaderLinksMobile {
  display: flex;
  width: 100%;
  margin-right: 8px;
  text-align: right;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-end;
}

.HeaderLink {
  color: white;
  text-decoration: none;
  padding: 16px;

  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
}

.HeaderLink:hover {
  font-weight: 700;
  cursor: pointer;
}

.HeaderLinkMobile {
  color: white;
  margin-right: 8px;
  text-decoration: none;
  position: relative;
  display: block;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: all 0.2s;
  cursor: pointer;
}

.HeaderLinksMobileContainer {
  display: flex;
  justify-content: space-between;
}

.HeaderContainerMobileExpanded {
  flex-direction: column;
  align-items: inherit;
}

.HeaderMenu {
  height: 36px;
  padding: 8px;
  margin-left: auto;
}

@media only screen and (max-width: 800px) {
  .HeaderContainer {
    /* flex-direction: column; */
    /* align-items: initial; */
  }
}
