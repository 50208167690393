.CustomSectionContainer {
  position: relative;
  width: calc(100% - 48px);
  /* min-height: calc(100vh - 48px); */
  padding: 64px 24px;
}

.CustomSectionImg {
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
  object-fit: cover;
}

.CustomSectionBody {
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
}

@media only screen and (max-width: 800px) {
  .CustomSectionImg {
    width: 92%;
  }
  .CustomSectionBody {
    width: 92%;
    padding: 4%;
    margin: 0;
  }
}
