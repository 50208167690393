.gallery-img {
  margin: 0 8px;
}

.gallery-img__container {
  height: 100%;
}

.gallery-modal-image {
  height: 100%;
}

.GalleryContainer {
  position: relative;
  width: calc(100% - 48px);
  padding: 24px;
}

.gallery-cary-slider__inner {
  margin-bottom: 16px;
}

.GalleryImg {
  width: 90%;
  object-fit: cover;
  vertical-align: middle;
  cursor: pointer;
}

.GalleryCardContainer {
  width: 80%;
}

.ReviewWrapper {
  background: #fff;
  position: relative;
}

.ReviewCard {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  margin: 16px 12px;
  padding: 24px;
  text-align: center;
}

.GalleryCardsNext {
  transition: all 0.2s;
  opacity: 0.4;
  cursor: pointer;
}

.GalleryCardsNext:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

.GalleryCardsPrev {
  transition: all 0.2s;
  opacity: 0.4;
  cursor: pointer;
}

.GalleryCardsPrev:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

@media only screen and (max-width: 800px) {
  .GalleryCardContainer {
    width: 100%;
    margin: 64px 0px;
  }
  .GalleryCardsNext {
    z-index: 11;
    transition: all 0.2s;
    opacity: 0.4;
    cursor: pointer;
  }

  .GalleryCardsPrev {
    z-index: 11;
    transition: all 0.2s;
    opacity: 0.4;
    cursor: pointer;
  }
}
